// 37 Малые архитектурные формы и элементы благоустройства
/* eslint-disable  */

import { getParseAttribute } from 'app/components/card/common/getParseAttribute';
import { parseDate } from 'app/components/card/common/parse';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import buildMandatoriesMessage from 'app/components/card/common/validation/buildMandatoriesMessage';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import LittleFormComponent from 'app/components/card/ogh/little_form/LittleFormComponent';
import submitCard from 'app/components/card/ogh/submit';
import { LITTLE_FORM } from 'app/constants/oghTypes';
import getMafTypeLevel2 from 'app/selectors/card/getMafTypeLevel2';
import getMafTypeLevel3 from 'app/selectors/card/getMafTypeLevel3';
import getUnitsByMafType from 'app/selectors/card/getUnitsByMafType';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import mafTypeService from 'app/services/mafTypeService';
import { transformValues } from 'app/utils/form/transformation';
import dateFormatService from 'core/services/dateFormatService';
import { ID_RFID } from 'core/uiKit/preparedInputs';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

const getMafTypeLevel1 = getDict('mafTypeLevel1');

const propTypes = {
  card: PropTypes.object,
  changeFieldValue: PropTypes.func,
  createMode: PropTypes.bool,
  documents: PropTypes.array,
  editMode: PropTypes.bool,
  find: PropTypes.func,
  formValues: PropTypes.object,
  mafTypeLevel1: PropTypes.array,
  mafTypeLevel2: PropTypes.array,
  mafTypeLevel3: PropTypes.array,
  mode: PropTypes.object,
  required: PropTypes.bool,
  units: PropTypes.array,
};

/**
 *
 * @param props
 */
const LittleFormCard = (props) => {
  /**
   *
   * @param id
   */
  const onChangeMafTypeLevel2Id = (id) => {
    if (id) {
      const { mafTypeLevel2 } = props;
      const { is_material } = mafTypeLevel2.find((item) => item.id === id);

      if (is_material) {
        resetMaterial();
      }
    }
  };

  /**
   *
   */
  const isEditableMaterial = () => {
    const {
      formValues: { maf_type_level2_id },
      mafTypeLevel2,
    } = props;
    let result;

    if (maf_type_level2_id && mafTypeLevel2) {
      const { is_material } = mafTypeLevel2.find(
        (item) => item.id === maf_type_level2_id,
      );

      result = !is_material;
    } else {
      result = true;
    }

    return result;
  };

  /**
   *
   */
  const resetMaterial = () => {
    const { changeFieldValue } = props;

    changeFieldValue('material_id', null);
  };

  return (
    <LittleFormComponent
      {...props}
      isEditableMaterial={isEditableMaterial}
      onChangeMafTypeLevel2Id={onChangeMafTypeLevel2Id}
    />
  );
};

/**
 *
 * @param state
 * @param props
 */
export const mapStateToProps = (state, props) => {
  // в функции потому что мок не работает
  const littleFormDocumentsReducer = documentsReducer({
    oghGroupCode: 'yard_subobject',
  });

  const parse = getParseAttribute(props);
  const units = getUnitsByMafType(state);
  const result = {
    createMode: isCreateMode(state, props),

    editMode: isEditMode(state, props),

    enableReinitialize: true,
    formValues: getFormValues(state),
    initialValues: {
      [ID_RFID]: parse(ID_RFID),
      eais_id: parse('eais_id'),

      endDate: parseDate(props.card.end_date),
      erz_date: parseDate(props.card.attribute.erz_date),
      erz_state_id: parse('erz_state_id.erz_state'),

      guarantee_period: parseDate(props.card.attribute.guarantee_period),

      installation_date: parseDate(props.card.attribute.installation_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      is_filled: Boolean(parse('is_filled')),
      lifetime: parseDate(props.card.attribute.lifetime),

      maf_quantity_characteristics: parse('maf_quantity_characteristics'),
      maf_type_level1_id: parse('maf_type_level1_id.maf_type_level1'),
      maf_type_level2_id: parse('maf_type_level2_id.maf_type_level2'),
      maf_type_level3_id: parse('maf_type_level3_id.maf_type_level3'),

      material_id: parse('material_id.material'),
      no_calc: parse('no_calc') ? parse('no_calc') : 0,
      parent_name: props.card.parent_info,
      quantity: parse('quantity'),
      startDate: parseDate(props.card.start_date),
      unit_id: parse('unit_id.unit'),
    },
    mafTypeLevel1: getMafTypeLevel1(state),

    mafTypeLevel2: getMafTypeLevel2(state),

    mafTypeLevel3: getMafTypeLevel3(state),
    onSubmit: onSubmit.bind({
      mafTypeLevel1: getMafTypeLevel1(state),
      props,
    }),
    required: isRequired(state, props),

    units,
    validate: validate({ units }),
  };

  return littleFormDocumentsReducer(result, state, props);
};

/**
 *
 * @param root0
 * @param root0.units
 * @param root0.values
 */
const getNumericFields = ({ units, values }) => {
  const numericFields = [
    {
      name: 'maf_quantity_characteristics',
      positive: true,
      type: 'integer',
      zero: true,
    },
  ];

  const unit = (units || []).find((item) => item.id === values.unit_id);
  if (['square_meters', 'running_meters'].includes(unit?.code)) {
    numericFields.push({
      name: 'quantity',
      positive: true,
      zero: false,
    });
  } else {
    numericFields.push({
      name: 'quantity',
      positive: true,
      type: 'integer',
      zero: false,
    });
  }

  return numericFields;
};

/**
 *
 * @param root0
 * @param root0.units
 */
const validate =
  ({ units }) =>
  (values) => {
    return validateNumeric(values, getNumericFields({ units, values }));
  };

/**
 * Создание атрибутов документа.
 *
 * @param {*} formValues - Значения формы.
 * @returns {*}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const dictFields = [
  'maf_type_level1_id',
  'maf_type_level2_id',
  'maf_type_level3_id',
  'unit_id',
  'material_id',
  'installation_date',
  'lifetime',
];

const mandatoryFields = {
  [LITTLE_FORM]: ['maf_type_level1_id', 'unit_id', 'quantity'],
};

const mandatoryFieldNames = {
  af_quantity_caracteristics: 'Количество мест, шт.',
  maf_type_level1_id: 'Вид МАФ',
  quantity: 'Количество МАФ',
  unit_id: 'Ед. изм',
};

const tabToFields = {
  'Исходные данные': [
    'unit_id',
    'quantity',
    'installation_date',
    'lifetime',
    'guarantee_period',
  ],
  Свойства: ['maf_type_level1_id', 'maf_quantity_characteristics'],
};

/**
 * On submit handler.
 *
 * @param {*} formValues - Form values.
 * @returns {void}
 */
function onSubmit(formValues) {
  /**
   *
   */
  const getQuantityCharacteristicsProps = () => {
    const mafTypeLevel1Id = formValues.maf_type_level1_id;
    const { mafTypeLevel1 } = this;

    return mafTypeService.getQuantityCharacteristicsProps(
      mafTypeLevel1,
      mafTypeLevel1Id,
    );
  };

  const { disabled: disabledMafQuantity } = getQuantityCharacteristicsProps();

  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[this.props.card.type_id],
    (params) => {
      if (!params.maf_quantity_characteristics && !disabledMafQuantity) {
        return { maf_quantity_characteristics: 'обязательное поле' };
      }
    },
    null,
    {
      /**
       *
       * @param errors
       */
      alertErrors: (errors) => {
        this.props.alert(
          buildMandatoriesMessage(errors, tabToFields, mandatoryFieldNames),
        );
      },
    },
  );

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      custom: {
        erz_date: {
          transformer,
        },
        guarantee_period: {
          transformer,
        },
        installation_date: {
          transformer,
        },
        lifetime: {
          transformer,
        },
      },
      dictFields,
      numericFields: getNumericFields({ units: this.props.units, values }),
    }),
  );
}

/**
 * Map state to props.
 *
 * @param {*} value - Value.
 * @returns {*}
 */
function transformer(value) {
  return value === null ? null : dateFormatService.formatDate(value);
}

/**
 * Map dispatch to props.
 *
 * @param {*} dispatch - Dispatch.
 * @returns {*}
 */
function mapDispatchToProps(dispatch) {
  return {
    /**
     *
     * @param field
     * @param value
     */
    changeFieldValue: (field, value) => {
      dispatch(change('editorCard', field, value));
    },
  };
}

LittleFormCard.propTypes = propTypes;

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(LittleFormCard));
