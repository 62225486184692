import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { ButtonSendMatchingOgh } from 'app/components/card/ogh/index/card/components/SendMatchingOgh.Button/Button.SendMatchingOgh';
import { joinIds } from 'app/components/card/ogh/index/card/components/SendMatchingOgh.Button/joinIds';
import { PROJECT } from 'app/constants/oghStatus';
import { fetchCheckObjectParent } from 'app/pages/cardsOgh/components/buttonsCard/components/SendMatching/api/fetchCheckObjectParent';
import { DialogSendMatching } from 'app/pages/cardsOgh/components/buttonsCard/components/SendMatching/DialogSendMatching';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { toast } from 'core/uiKit/components';
import { useGetObjectId } from 'core/utils/hooks/useGetObjectId';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import { log } from 'core/utils/log';
import React, { useState } from 'react';

/* eslint-disable */
export const SendMatching = () => {
  const { objectId } = useGetObjectId();
  const card = useGetCurrentCard();
  const [dialog, setDialog] = useState({
    dataRequest: null,
    open: false,
  });
  const { editMode } = useEditMode();

  const [checkObjectParent] = useMutationAdaptor(fetchCheckObjectParent, {
    onSuccess(obj) {
      const rootIsProject = card.logicalStateId === PROJECT;
      if (Object.keys(card) && rootIsProject) {
        const ids = joinIds(obj);
        if (ids) {
          toast.errorDying(
            `Данная версия объекта является элементом объекта(ов) с id ${ids}. Согласование невозможно`,
          );
        } else {
          setDialog({ dataRequest: obj, open: true });
        }
      } else setDialog({ dataRequest: obj, open: true });
    },
    onError() {
      log.banana('fetchCheckObjectParent не прошел');
    },
  });

  const checkObjectParentHandler = () => {
    checkObjectParent({
      id: card.rootId,
      date: card.startDate,
    });
  };

  return (
    <>
      <DialogSendMatching open={dialog.open} setOpen={setDialog} />
      {!editMode ? (
        <ButtonSendMatchingOgh
          objectId={+objectId}
          onClick={checkObjectParentHandler}
        />
      ) : null}
    </>
  );
};
