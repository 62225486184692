import { OZN, YARD } from 'app/constants/oghTypes';
import { createNumberedList } from 'app/utils/createNumberedList';

/**
 * Получение подсказки по typeID Площадь ручной уборки.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const getHintManualCleanArea = (typeId) => {
  const cleaningMethodChildren = [
    '"Плоскостные сооружения".',
    '"Дорожно-тропиночные сети"',
  ];

  switch (typeId) {
    case YARD: {
      cleaningMethodChildren.push({
        children: ['Пешеходный мост', 'Пирс', 'Cухой фонтан'],
        item: '"Инженерные сооружения"',
      });
      break;
    }
    case OZN: {
      cleaningMethodChildren.push({
        children: [
          'Бассейн',
          'Пешеходный мост',
          'Пирс (причал)',
          'Променад',
          'Сухой фонтан',
        ],
        item: '"Инженерные сооружения"',
      });
      break;
    }
    default: {
      break;
    }
  }

  return createNumberedList({
    header: 'Рассчитывается как сумма значений',
    list: [
      {
        children: cleaningMethodChildren,
        item: '"Площадь, кв.м." ("Способ уборки": "Ручная уборка")',
      },
      {
        children: [
          '"Закрытые велопарковки"',
          '"Места сборов отходов"',
          '"Газоны"',
        ],
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"ИОКС типа Отмостка"'],
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"Цветники"'],
        item: '"Общая площадь цветника, кв.м"',
      },
      {
        children: [
          'Элементы организации рельефа типа Лестницы',
          'Элементы благоустройства для маломобильных групп населения типа Пандус',
        ],
        item: 'Количество, кв.м.',
      },
    ],
  });
};
