import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { WithMapContext } from 'app/components/map/withMap';
import { INTERSECTIONS } from 'app/constants/layers';
import { switchServisMap } from 'core/utils/map/switchServisMap';
import { useContext } from 'react';

/**
 * Хук получения контекста.
 *
 * @returns {{isLoading: boolean, objGeoData: *, data: unknown, messages: (*|string)}}
 */
export const useDrawDrawIntersectionsGeometry = () => {
  const currentCard = useGetCurrentCard();
  const {
    clearLayer = () => {},
    drawIntersections = () => {},
    drawIntersectionsGeometry = () => {},
    getGeometries = () => {},
    getGeometry = () => {},
  } = useContext(WithMapContext);

  return switchServisMap({

    /**
     *
     * @param geometry
     */
    dtw: {

      /**
       * Наричовать гометрию.
       *
       * @param {object} geometry - Геометрия.
       * @returns {void}
       */
      drawIntersections: (geometry) => drawIntersections(geometry),
      geometry: getGeometries(currentCard.record_id),
    },
    egip: {

      /**
       * Наричовать гометрию.
       *
       * @param {object} geometry - Геометрия.
       * @returns {void}
       */
      drawIntersections: (geometry) => {
        clearLayer(INTERSECTIONS);
        drawIntersectionsGeometry(geometry);
      },

      geometry: getGeometry(currentCard.record_id),
    },
  });
};
