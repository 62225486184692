import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useEditMode } from 'app/pages/cardsOgh/hooks/useEditMode';
import { IsOrphanObjectRF } from 'core/form/reduxForm/fields/prepared/checkboxes/IsOrphanObject.RF';
import React from 'react';

/**
 * Чек бокс __Объект переданный в эксплуатацию.
 *
 * @returns {JSX.Element}
 */
export const IsOrphanObjectRfField = () => {
  const { editMode } = useEditMode();
  const card = useGetCurrentCard();

  return <IsOrphanObjectRF disabled={!(editMode && !card?.rootId)} />;
};
