import { OZN, YARD } from 'app/constants/oghTypes';
import { createNumberedList } from 'app/utils/createNumberedList';

/**
 * Общая площадь объекта, кв м.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const getHintTotalArea = (typeId) => {
  const squareChildren = [
    '"Газоны"',
    '"Дорожно-тропиночные сети"',
    '"Закрытые велопарковки"',
    '"Иные некапитальные объекты"',
    '"ИОКС типа Архитектурный памятник"',
    '"ИОКС типа Объект монументального искусств"',
    '"ИОКС типа Отмостка"',
    '"Места сборов отходов"',
    '"Некапитальные объекты для обеспечения производственной деятельности по содержанию и ремонту территорий (в том числе базы хранения ПГР на дворовых территориях)"',
    '"Плоскостные сооружения"',
    '"Водоемы"',
  ];

  if ([OZN, YARD].includes(typeId)) {
    squareChildren.push('"Инженерные сооружения"');
  }

  return createNumberedList({
    header: 'Рассчитывается как сумма значений',
    list: [
      {
        children: ['"Объекты капитального строительства"'],
        item: '"Площадь застройки, кв.м."',
      },
      {
        children: squareChildren,
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"Цветники"'],
        item: '"Общая площадь цветника, кв.м"',
      },
      {
        children: [
          'Элементы благоустройства для маломобильных групп населения типа Пандус',
          'Элементы организации рельефа типа Лестницы, Подпорные стены, Габион, Системы укрепления откосов',
          'Элементы системы функционального обеспечения типа Лоток водосточный (решетка), Шахта вентиляционной камеры.',
        ],
        item: 'Количество, кв.м.',
      },
    ],
  });
};
