import './DialogSendMatching.scss';

/* eslint-disable  */
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { fetchMatchingSend } from 'app/pages/cardsOgh/components/buttonsCard/components/SendMatching/api/fetchMatchingSend';
import { SendReasonFilter } from 'app/pages/cardsOgh/components/buttonsCard/components/SendMatching/fields/SendReason.Filter';
import { validateDialog } from 'app/pages/cardsOgh/components/buttonsCard/components/SendMatching/validateDialog';
import { ContextDialog } from 'app/pages/cardsOgh/components/DIalogs/Dialogs';
import { FinalForm } from 'core/form/finalForm/components/FinalForm';
import { TextFieldFF } from 'core/form/finalForm/fields';
import { Button } from 'core/uiKit/components/buttons/Button';
import { Dialog } from 'core/uiKit/components/dialogs/Dialog';
import { useMutationAdaptor } from 'core/utils/hooks/useReactQuery';
import React, { useContext } from 'react';

/**
 * DialogSendMatching.
 *
 * @param {*} props - The props.
 * @param {boolean} props.open - Open.
 * @param {Function} props.setOpen - F.
 * @returns {JSX.Element}
 */
export const DialogSendMatching = ({ open, setOpen }) => {
  const { rootId, startDate, parentId, typeId, refetch } = useGetCurrentCard();
  const { showAlert } = useContext(ContextDialog);

  /**
   * Close the dialog.
   *
   * @returns {void}
   */
  const closeDialog = () => {
    setOpen((pre) => ({ ...pre, open: false }));
  };

  const [matchingSend, { isLoading }] = useMutationAdaptor(fetchMatchingSend, {
    /**
     *
     * @param err
     */
    onError: (err) => {
      showAlert(err.message);
      closeDialog();
    },

    /**
     *
     */
    onSuccess: () => {
      showAlert('Объект отправлен на согласование.');
      closeDialog();
      refetch();
    },
  });

  /**
   * OnSubmit.
   *
   * @param {*} values - Values.
   * @returns {*}
   */
  const onSubmit = (values) => {
    const errors = validateDialog(values);
    if (Object.values(errors).length) return errors;
    matchingSend({
      ...values,
      id: rootId,
      lastMatchDate: startDate,
      typeChange: parentId ? 'edit_ogh' : 'create_ogh',
      typeId,
    });
  };

  return (
    <Dialog
      maxWidth={'sm'}
      isOpen={open}
      textHeader={'Отправить объект на согласование'}
      Body={
        <FinalForm onSubmit={onSubmit}>
          <div className={'dialog-send-matching-card'}>
            <div>
              <SendReasonFilter />
              <TextFieldFF
                name={'comment'}
                label={'Комментарий'}
                variant={'outlined'}
                maxLength={1000}
              />
            </div>
            <div className={'dialog-send-matching-card__wrapper-button'}>
              <Button color={'info'} variant={'outlined'} onClick={closeDialog}>
                Закрыть
              </Button>
              <Button
                color={'primary'}
                variant={'contained'}
                type="submit"
                loading={isLoading}
              >
                Продолжить
              </Button>
            </div>
          </div>
        </FinalForm>
      }
    />
  );
};
