import mafTypeService from 'app/services/mafTypeService';
import { NumberFieldRF } from 'core/form/reduxForm/fields/default/NumberField.RF';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import PropTypes from 'prop-types';
import React from 'react';

import { MafTypeLevel1RFWrapper } from './MafTypeLevel1RFWrapper';

/**
 * MafType.
 *
 * @param {object} props - Property.
 * @returns {JSX}
 */
const MafType = (props) => {
  const {
    changeFieldValue,
    editable,
    isEditCurrentObjectIsExternalSystem,
    mafTypeLevel1Id,
    mafTypeLevel1,
    mafTypeLevel2,
    mafTypeLevel3,
    onChangeMafTypeLevel2Id,
    parentTypeId,
    required,
  } = props;

  /**
   * Function.
   *
   * @param {*} mafTypeLevel1Id - A.
   * @returns {*}
   */
  const getQuantityCharacteristicsProps = (mafTypeLevel1Id) => {
    const { mafTypeLevel1 } = props;
    return mafTypeService.getQuantityCharacteristicsProps(
      mafTypeLevel1,
      mafTypeLevel1Id,
    );
  };

  const {
    label: labelMafQuantityCharacteristics,
    disabled: disabledMafQuantityCharacteristics,
  } = getQuantityCharacteristicsProps(mafTypeLevel1Id);

  const editableMafQuantityCharacteristics =
    editable && !disabledMafQuantityCharacteristics;

  return (
    <FieldsSplitColumns>
      <MafTypeLevel1RFWrapper
        disabled={!(editable && isEditCurrentObjectIsExternalSystem)}
        required={required}
        mafTypeLevel1={mafTypeLevel1}
        getQuantityCharacteristicsProps={getQuantityCharacteristicsProps}
        changeFieldValue={changeFieldValue}
        parentTypeId={parentTypeId}
      />
      <SelectRF
        name={'maf_type_level2_id'}
        label={'Уточнение'}
        options={mafTypeLevel2}
        withChange={onChangeMafTypeLevel2Id}
        disabled={!(editable && mafTypeLevel2 && mafTypeLevel2.length)}
      />
      <SelectRF
        name={'maf_type_level3_id'}
        label={'Детализация'}
        options={
          parentTypeId
            ? mafTypeLevel3?.filter(({ parent_ogh_object_type_list = [] }) =>
                parent_ogh_object_type_list?.includes(parentTypeId),
              )
            : mafTypeLevel3
        }
        disabled={!(editable && mafTypeLevel3 && mafTypeLevel3.length)}
        withChange={(mafTypeLevel3Id) => {
          const mafTypeObject = (mafTypeLevel3 || []).find(
            (item) => item.id === mafTypeLevel3Id,
          );
          const unitId = mafTypeObject ? mafTypeObject.unit_id : null;

          changeFieldValue('unit_id', unitId);
        }}
      />
      <NumberFieldRF
        {...{
          disabled: !editableMafQuantityCharacteristics,
          id: 'maf_quantity_characteristics',
          label: labelMafQuantityCharacteristics,
          name: 'maf_quantity_characteristics',
          required: editableMafQuantityCharacteristics,
        }}
      />
    </FieldsSplitColumns>
  );
};

MafType.propTypes = {
  changeFieldValue: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  mafTypeLevel1: PropTypes.array,
  mafTypeLevel1Id: PropTypes.number,
  mafTypeLevel2: PropTypes.array,
  mafTypeLevel3: PropTypes.array,
  onChangeMafTypeLevel2Id: PropTypes.func.isRequired,
  required: PropTypes.bool,
  typeId: PropTypes.number,
};

export default MafType;
