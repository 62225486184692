/* eslint-disable no-case-declarations */
import _ from 'lodash';

/**
 * Функция transformValues.
 *
 * @param {object} formValues - Значения формы.
 * @param {*} fieldTypes - Жопа.
 * @returns {{interval: object, attribute: object}} - Возвращает преобразованные значения и добовляет в поля interval и attribute.
 * @example submitCard(
 *     this.props,
 *     transformValues(formValuesWithAttribute, {
 *       dictFields,
 *       numericFields,
 *       custom: {
 *         address_list: {
 *           transformer: (value) => {
 *             return value == null || value.length === 0
 *               ? null
 *               : value.map((item) => {
 *                   return mapValues(
 *                     pick(item, [
 *                       'okrug_id',
 *                       'address_id',
 *                       'street_id',
 *                       'district_id',
 *                       'description',
 *                     ]),
 *                     (id) => ({ bti_object: id }),
 *                   );
 *                 });
 *           },
 *         },
 *       },
 *     }),
 *   );
 */
export function transformValues(formValues, fieldTypes) {
  const attribute = {};
  const interval = {};
  Object.keys(formValues).forEach((key) => {
    if (
      key !== 'no_calc' &&
      (formValues[key] === null || formValues[key] === '')
    ) {
      return;
    }

    switch (true) {
      case fieldTypes.custom && _.includes(_.keys(fieldTypes.custom), key):
        const newKey = fieldTypes.custom[key].newKey || key;
        const formValue = formValues[key];
        const transformer = fieldTypes.custom[key].transformer;
        const transformedValue = transformer
          ? transformer(formValue)
          : formValue;
        if (transformedValue !== null) {
          return (attribute[newKey] = transformedValue);
        }
        break;

      case formValues[key] &&
        formValues[key].table &&
        formValues[key].table.length === 0:
        attribute[key] = null;
        break;

      case _.includes(key.toLowerCase(), 'date'):
        interval[key] = new Intl.DateTimeFormat('ru').format(formValues[key]);
        break;

      case key === 'buildingsTypeSpecId':
        attribute[key] = {
          buildingsTypeSpec: formValues[key],
        };
        break;

      case key === 'car_equipment_owner_id':
        attribute[key] = {
          legal_person: formValues[key].id,
        };
        break;

      case _.includes(['customer_id', 'owner_id'], key):
        if (formValues[key]?.id && formValues[key]?.id !== null) {
          attribute[key] = {
            legal_person: parseInt(formValues[key].id, 10),
          };
        }
        break;

      case key === 'state_gardening_id':
        attribute[key] = {
          state_gardening: formValues[key],
        };
        break;

      case key === 'coating_group_road_id':
        attribute[key] = { coating_group: formValues[key] };
        break;

      case key === 'coating_face_type_id':
        attribute[key] = { coating_type: formValues[key] };
        break;

      case key === 'coating_type_road_id':
        attribute[key] = { coating_type: formValues[key] };
        break;

      case key === 'lawn_type_id':
        attribute[key] = { lawn_type: formValues[key] };
        break;

      case _.includes(['not_odh_area', 'detached'], key):
        formValues[key] === 0
          ? (attribute[key] = false)
          : (attribute[key] = true);
        break;

      case key === 'material_id':
        attribute.material_id = {
          material: formValues[key],
        };
        break;

      case fieldTypes.dictFields && _.includes(fieldTypes.dictFields, key):
        const dictKey = dictMapping[key] || key.replace('_id', '');
        attribute[key] = dictValue(dictKey, formValues[key]);
        break;

      case fieldTypes.ajaxFields && _.includes(fieldTypes.ajaxFields, key):
        if (formValues[key].id !== null) {
          const dictKey = key.replace('_id', '');
          attribute[key] = dictValue(dictKey, parseInt(formValues[key].id, 10));
        }
        break;

      case fieldTypes.numericFields &&
        _.findIndex(fieldTypes.numericFields, ['name', key]) !== -1:
        attribute[key] = parseFloat(
          String(formValues[key]).replace(/[,]+/g, '.'),
        );
        break;

      case fieldTypes.integerField && _.includes(fieldTypes.integerField, key):
        attribute[key] = parseInt(formValues[key], 10);
        break;

      case key === 'address_list':
        const value = formValues[key];
        const array = Array.isArray(value) ? [...value] : [value];
        attribute[key] = array.map((item) => {
          const newItem = { ...item };
          delete newItem.address_name;
          if (!newItem.description) {
            newItem.description = null;
          }
          if (!newItem.okrug_id || Object.keys(newItem.okrug_id).length === 0) {
            newItem.okrug_id = { bti_object: null };
          }
          if (
            !newItem.district_id ||
            Object.keys(newItem.district_id).length === 0
          ) {
            newItem.district_id = { bti_object: null };
          }
          if (
            !newItem.street_id ||
            Object.keys(newItem.street_id).length === 0
          ) {
            newItem.street_id = { bti_object: null };
          }
          if (
            !newItem.address_id ||
            Object.keys(newItem.address_id).length === 0
          ) {
            newItem.address_id = { bti_object: null };
          }
          return newItem;
        });
        break;

      case key === 'no_calc':
        attribute.no_calc = Number(formValues[key]);
        break;

      default:
        attribute[key] = formValues[key];
        break;
    }
  });

  return { attribute, interval };
}

/**
 * Функция dictValue.
 *
 * @param {string} dictKey - Ключ.
 * @param {*} value - Значение.
 * @returns {{}} - Объект.
 * @example attribute[key] = dictValue(dictKey, parseInt(formValues[key].id, 10));
 */
const dictValue = (dictKey, value) => {
  let newValue = value;
  if (Array.isArray(value)) {
    newValue = newValue.filter(Boolean);
  }
  return { [dictKey]: newValue };
};

const dictMapping = {
  conn_covering_type_id: 'covering_type',
  path_covering_type_id: 'covering_type',
  territory_class_id: 'class',
};
