import LegendMapDiff from 'app/components/common/LegendMapDiff';
import withMap, { WithMapContext } from 'app/components/map/withMap';
/* eslint-disable  */
import { copyColors, originalColors } from 'app/constants/colors';
import formatRootId from 'app/utils/formatRootId';
import compareReportStyles from 'assets/styles/common.compareReport.scss';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const propTypes = {
  Map: PropTypes.func.isRequired,
  className: PropTypes.string,
  copy: PropTypes.object,
  etalon: PropTypes.object,
  geodiff: PropTypes.object,
  isMapLoaded: PropTypes.bool.isRequired,
};

/**
 * Класс MapDiff.
 *
 */
class MapDiff extends React.Component {
  /**
   * Конструктор.
   *
   * @param {object} props - Пропсы.
   */
  constructor(props) {
    super(props);
    this.state = {
      showCopy: true,
      showOriginal: true,
    };
  }
  static contextType = WithMapContext;

  /**
   * Метод жизненного цикла componentDidUpdate.
   *
   * @returns {void}
   */
  componentDidUpdate() {
    const { isMapLoaded, geodiff, copy, etalon } = this.props;
    const { showCopy, showOriginal } = this.state;

    if (isMapLoaded && geodiff) {
      this.context.clearDiff();

      if (showCopy === true) {
        this.context.drawDiffCopyObjectGeometry([
          {
            hint: geodiff.copy_hint,
            id: copy.id,
            lines: geodiff.copy_lines,
            points: geodiff.copy_points,
            polygons: geodiff.copy_polygons,
          },
        ]);
      }

      if (showOriginal === true) {
        this.context.drawDiffOriginalObjectGeometry([
          {
            hint: geodiff.original_hint,
            id: etalon.id,
            lines: geodiff.original_lines,
            points: geodiff.original_points,
            polygons: geodiff.original_polygons,
          },
        ]);
      }

      this.context.zoomToDiff();
    }
  }

  /**
   * Функция showObjects.
   *
   * @param {object} args - Аргументы.
   * @returns {void}
   */
  showObjects = (args) => {
    if (args.copy === true) {
      this.setState({ showCopy: true });
    } else {
      this.setState({ showCopy: false });
    }

    if (args.original === true) {
      this.setState({ showOriginal: true });
    } else {
      this.setState({ showOriginal: false });
    }
  };

  /**
   * Метод жизненного цикла render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const { geodiff, etalon, copy } = this.props;

    return (
      <div className={classnames('d-flex flex-column', this.props.className)}>
        <div className="my-3 h-100 overflow-hidden">
          {geodiff &&
            this.props.Map({
              className: 'w-100 h-100 diff__legend',
              editable: false,
              style: {
                height: '100%',
                width: '100%',
              },
            })}
        </div>

        {geodiff && geodiff.is_equal === 0 && (
          <dl>
            <div className="d-inline mr-3">
              <dt className="d-inline">Статус:</dt>{' '}
              <dd className="d-inline">Не равно</dd>
            </div>
            <div className="d-inline">
              <dt className="d-inline">Уточнение статуса:</dt>{' '}
              <dd className="d-inline">
                {geodiff.operation === 'update'
                  ? 'Отличается'
                  : geodiff.operation === 'add'
                  ? 'Новое'
                  : geodiff.operation === 'del'
                  ? 'Удалено'
                  : ''}
              </dd>
            </div>
          </dl>
        )}

        <LegendMapDiff
          action={this.showObjects}
          legend={[
            {
              className: compareReportStyles.legend_add,
              style: {
                background: copyColors.fillColor,
              },
              value: `Версия ID: ${formatRootId(copy.id)} (${
                copy.object_status_name
              })`,
            },
            {
              className: compareReportStyles.legend_update,
              style: {
                background: originalColors.fillColor,
              },
              value: `Версия ID: ${formatRootId(etalon.id)} (${
                etalon.object_status_name
              })`,
            },
          ]}
        />
      </div>
    );
  }
}

MapDiff.propTypes = propTypes;

export default withMap(MapDiff);
