import { OZN, YARD } from 'app/constants/oghTypes';
/* eslint-disable */
import { RANDOM_ID } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { getHintTotalArea } from './hint.totalArea';

vi.mock('app/utils/createNumberedList', () => ({
  /**
   *
   * @param obj
   */
  createNumberedList: (obj) => obj,
}));

describe('HintTotalArea', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintTotalArea(RANDOM_ID)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: ['"Объекты капитального строительства"'],
          item: '"Площадь застройки, кв.м."',
        },
        {
          children: [
            '"Газоны"',
            '"Дорожно-тропиночные сети"',
            '"Закрытые велопарковки"',
            '"Иные некапитальные объекты"',
            '"ИОКС типа Архитектурный памятник"',
            '"ИОКС типа Объект монументального искусств"',
            '"ИОКС типа Отмостка"',
            '"Места сборов отходов"',
            '"Некапитальные объекты для обеспечения производственной деятельности по содержанию и ремонту территорий (в том числе базы хранения ПГР на дворовых территориях)"',
            '"Плоскостные сооружения"',
            '"Водоемы"',
          ],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"Цветники"'],
          item: '"Общая площадь цветника, кв.м"',
        },
        {
          children: [
            'Элементы благоустройства для маломобильных групп населения типа Пандус',
            'Элементы организации рельефа типа Лестницы, Подпорные стены, Габион, Системы укрепления откосов',
            'Элементы системы функционального обеспечения типа Лоток водосточный (решетка), Шахта вентиляционной камеры.',
          ],
          item: 'Количество, кв.м.',
        },
      ],
    });
  });
  it('typeId OZN', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintTotalArea(OZN)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: ['"Объекты капитального строительства"'],
          item: '"Площадь застройки, кв.м."',
        },
        {
          children: [
            '"Газоны"',
            '"Дорожно-тропиночные сети"',
            '"Закрытые велопарковки"',
            '"Иные некапитальные объекты"',
            '"ИОКС типа Архитектурный памятник"',
            '"ИОКС типа Объект монументального искусств"',
            '"ИОКС типа Отмостка"',
            '"Места сборов отходов"',
            '"Некапитальные объекты для обеспечения производственной деятельности по содержанию и ремонту территорий (в том числе базы хранения ПГР на дворовых территориях)"',
            '"Плоскостные сооружения"',
            '"Водоемы"',
            '"Инженерные сооружения"',
          ],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"Цветники"'],
          item: '"Общая площадь цветника, кв.м"',
        },
        {
          children: [
            'Элементы благоустройства для маломобильных групп населения типа Пандус',
            'Элементы организации рельефа типа Лестницы, Подпорные стены, Габион, Системы укрепления откосов',
            'Элементы системы функционального обеспечения типа Лоток водосточный (решетка), Шахта вентиляционной камеры.',
          ],
          item: 'Количество, кв.м.',
        },
      ],
    });
  });

  it('typeId YARD', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintTotalArea(YARD)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: ['"Объекты капитального строительства"'],
          item: '"Площадь застройки, кв.м."',
        },
        {
          children: [
            '"Газоны"',
            '"Дорожно-тропиночные сети"',
            '"Закрытые велопарковки"',
            '"Иные некапитальные объекты"',
            '"ИОКС типа Архитектурный памятник"',
            '"ИОКС типа Объект монументального искусств"',
            '"ИОКС типа Отмостка"',
            '"Места сборов отходов"',
            '"Некапитальные объекты для обеспечения производственной деятельности по содержанию и ремонту территорий (в том числе базы хранения ПГР на дворовых территориях)"',
            '"Плоскостные сооружения"',
            '"Водоемы"',
            '"Инженерные сооружения"',
          ],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"Цветники"'],
          item: '"Общая площадь цветника, кв.м"',
        },

        {
          children: [
            'Элементы благоустройства для маломобильных групп населения типа Пандус',
            'Элементы организации рельефа типа Лестницы, Подпорные стены, Габион, Системы укрепления откосов',
            'Элементы системы функционального обеспечения типа Лоток водосточный (решетка), Шахта вентиляционной камеры.',
          ],
          item: 'Количество, кв.м.',
        },
      ],
    });
  });
});
