/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { Select } from 'core/uiKit/inputs/selects/Select';

import { cleaningTypes } from './cleaningTypes';

 
export const CLEANING_TYPE_NAME = 'cleaningType';

 
 
/**
 * ## Способ уборки
 *
 * `type = Select`
 *
 * `name = cleaningType`
 * `name = cleaning_type`
 *
 * `name = CLEANING_TYPE_NAME`
 *
 *
 */
 
export const CleaningType = (props) => {
  return (
    <Select
      label={'Способ уборки'}
      withAll={false}
      filter={(data) => data}
      {...props}
      name={CLEANING_TYPE_NAME}
      options={cleaningTypes}
    />
  );
};

CleaningType.propTypes = {
   
   
/**
   * очищаем инпут с сохранением значения
   */
  clear: PropTypes.bool,
   
   
/**
   * выбор темной темы
   */
  dark: PropTypes.bool,
   
   
/**
   * заблокировать на редактирование
   */
  disabled: PropTypes.bool,
   
   
/**
   * вывод ошибки
   */
  errorText: PropTypes.string,
   
   
/**
   * вывод вспомогательного текста
   */
  helperText: PropTypes.string,
   
   
/**
   * функция вызова когда выберется итем
   */
  onChange: PropTypes.func,
   
   
/**
   * вывод информации об обязательном поле
   */
  required: PropTypes.bool,
   
   
/**
   * значение
   */
  value: PropTypes.any,
};
