import {
  USER_NAME,
  UserName,
} from 'core/uiKit/preparedInputs/textField/UserName';
import React from 'react';
import { Field } from 'react-final-form';

/**
 * UserNameFF.
 *
 * @param {object} props - Свойства.
 * @returns {JSX.Element}
 */
export const UserNameFF = (props) => {
  return (
    <Field name={USER_NAME}>
      {({ input }) => {
        return <UserName {...props} {...input} />;
      }}
    </Field>
  );
};
