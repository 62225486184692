import { shallow } from 'enzyme';
import { vi } from 'vitest';

import BoardStoneCard from './board_stone';

vi.mock('dateformat', () => ({
  DateFormat: vi.fn(),
}));

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', () => ({
  ...vi.importActual('react-redux'),
  connect: vi.fn(() => (ba) => {
    return ba;
  }),
}));

describe('BoardStone', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { material: [], type_id: 1 },
      changeFieldValue: vi.fn(),
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };
    // 🔥 Act
    const wrapper = shallow(<BoardStoneCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardContainer />');
  });
});
