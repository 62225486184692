import { OZN, YARD } from 'app/constants/oghTypes';
/* eslint-disable */
import { RANDOM_ID } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { getHintManualCleanArea } from './hint.manualСleanArea';
vi.mock('app/utils/createNumberedList', () => ({
  /**
   *
   * @param obj
   */
  createNumberedList: (obj) => obj,
}));

describe('HintManualСleanArea', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintManualCleanArea(RANDOM_ID)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: ['"Плоскостные сооружения".', '"Дорожно-тропиночные сети"'],
          item: '"Площадь, кв.м." ("Способ уборки": "Ручная уборка")',
        },
        {
          children: [
            '"Закрытые велопарковки"',
            '"Места сборов отходов"',
            '"Газоны"',
          ],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"ИОКС типа Отмостка"'],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"Цветники"'],
          item: '"Общая площадь цветника, кв.м"',
        },
        {
          children: [
            'Элементы организации рельефа типа Лестницы',
            'Элементы благоустройства для маломобильных групп населения типа Пандус',
          ],
          item: 'Количество, кв.м.',
        },
      ],
    });
  });

  it('type Id OZN', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintManualCleanArea(OZN)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: [
            '"Плоскостные сооружения".',
            '"Дорожно-тропиночные сети"',
            {
              children: [
                'Бассейн',
                'Пешеходный мост',
                'Пирс (причал)',
                'Променад',
                'Сухой фонтан',
              ],
              item: '"Инженерные сооружения"',
            },
          ],
          item: '"Площадь, кв.м." ("Способ уборки": "Ручная уборка")',
        },
        {
          children: [
            '"Закрытые велопарковки"',
            '"Места сборов отходов"',
            '"Газоны"',
          ],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"ИОКС типа Отмостка"'],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"Цветники"'],
          item: '"Общая площадь цветника, кв.м"',
        },
        {
          children: [
            'Элементы организации рельефа типа Лестницы',
            'Элементы благоустройства для маломобильных групп населения типа Пандус',
          ],
          item: 'Количество, кв.м.',
        },
      ],
    });
  });

  it('type Id YARD', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    // 🔥 Act

    // ❓ Assert
    expect(getHintManualCleanArea(YARD)).toStrictEqual({
      header: 'Рассчитывается как сумма значений',
      list: [
        {
          children: [
            '"Плоскостные сооружения".',
            '"Дорожно-тропиночные сети"',
            {
              children: ['Пешеходный мост', 'Пирс', 'Cухой фонтан'],
              item: '"Инженерные сооружения"',
            },
          ],
          item: '"Площадь, кв.м." ("Способ уборки": "Ручная уборка")',
        },
        {
          children: [
            '"Закрытые велопарковки"',
            '"Места сборов отходов"',
            '"Газоны"',
          ],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"ИОКС типа Отмостка"'],
          item: '"Площадь, кв.м."',
        },
        {
          children: ['"Цветники"'],
          item: '"Общая площадь цветника, кв.м"',
        },
        {
          children: [
            'Элементы организации рельефа типа Лестницы',
            'Элементы благоустройства для маломобильных групп населения типа Пандус',
          ],
          item: 'Количество, кв.м.',
        },
      ],
    });
  });
});
