import { objectMatching } from 'app/actions/adminActions';
import { clearCard } from 'app/actions/clearCardStore';
import { hideDialog, showAlert, showNotification } from 'app/actions/dialog';
import {
  fetchCheckGeomContains,
  fetchCheckGeometryIntersections,
  fetchObjectsGeometry,
} from 'app/actions/geometryActions';
import {
  afterReceiving,
  cancelEditCard,
  cancelLeaveCurrentElement,
  cancelMode,
  createCard,
  fetchOgh,
  fetchPreviewCard,
  rollbackTreeChanges,
  sendCreateCard,
  sendDeleteCard,
  sendUpdateCard,
  viewCard,
} from 'app/actions/odsObjectActions';
import {
  fetchFewTree,
  fetchOghTree,
  fetchPreviewTreeSelectedElement,
  fetchTreeSelectedElement,
  treeDataRecalculate,
  treeSelectRoot,
} from 'app/actions/odsObjectTreeActions';
import { toggleCompareReportVisibility } from 'app/actions/odsObjectVersionsActions';
import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import withMap from 'app/components/map/withMap';
import { useActionsMap } from 'app/pages/cardsOgh/components/Map/useActionsMap';
import { useGetTreePanelData } from 'app/pages/cardsOgh/components/RightPanelCard/components/TreeRightPanel/hooks/useGetTreePanelData/useGetTreePanelData';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import getTreeJournal from 'app/selectors/card/getTreeJournal';
import hasGeometry from 'app/selectors/card/hasGeometry';
import getFormValues from 'app/selectors/form/getFormValues';
import { useGetParamsUrl } from 'core/utils/hooks/useGetParamsUrl';
import { useNavigateRouter } from 'core/utils/router';
import { flow, get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NumberParam, useQueryParams } from 'use-query-params';

import OghCard from './card';

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Стейт.
 * @returns {object}
 */
function mapStateToProps(state = {}) {
  return {
    action: state?.matching?.afterAction,
    actionsTree: state.tree?.actionsTree || {},
    addChildInfo: state?.tree?.addChildInfo,
    card: state?.card?.object,
    currentFormValues: getFormValues(state),
    goToRegistry: state?.matching?.goToRegistry,
    hasGeometry: hasGeometry(state),
    matching: state?.matching?.data,
    matchingRegistryColumns: state?.tableColumns?.objectMatchingRegistry,
    mode: state?.card?.mode,
    parentTypeId: get(state, 'registry.data.meta.parentTypeId'),
    selected: state?.tree?.object,
    treeData: get(state, 'card.mode.editMode')
      ? state.tree?.dataForModify || []
      : state.tree?.data || [],
    treeJournal: getTreeJournal(state),
    treeOghModal: state?.modals?.treeEdit,
    treeParams: state?.card?.current,
    versionsModal: state?.modals?.versions,
  };
}

const mapDispatchToProps = {
  afterReceiving,
  cancelEditCard,
  cancelLeaveCurrentElement,
  cancelMode,
  clearCard,
  createCard,
  fetchCheckGeomContains,
  fetchCheckGeometryIntersections,
  fetchFewTree,
  fetchObjectsGeometry,
  fetchPreviewCard,
  fetchPreviewTreeSelectedElement,
  fetchRootCard: fetchOgh,
  fetchTree: fetchOghTree,
  fetchTreeSelectedElement,
  hideDialog,
  objectMatching,
  rollbackTreeChanges,
  sendCreateCard: sendCreateCard('ogh'),
  sendDeleteCard: sendDeleteCard('ogh'),
  sendUpdateCard: sendUpdateCard('ogh'),
  showAlert,
  showNotification,
  toggleCompareReportVisibility,
  treeDataRecalculate,
  treeSelectRoot,
  viewCard,
};

const propTypes = {
  createCard: PropTypes.func,
  fetchPreviewCard: PropTypes.func,
  params: PropTypes.object,
  router: PropTypes.object,
  startDate: PropTypes.string,
};

/**
 * HOC компонент создания карточки ОГХ.
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
const CreateHOC = (props) => {
  return <Create {...props} />;
};

/**
 * Компонент Создания карточки ОГХ (Старый).
 *
 * @param {object} props - Пропсы.
 * @returns {JSX.Element}
 */
const Create = (props) => {
  useActionsMap();
  const paramsUrl = useGetParamsUrl();
  const location = useLocation();
  const navigate = useNavigateRouter();
  const { isSuccessParent, ...parentCard } = useGetParentCard();
  const { removeTree } = useGetTreePanelData();
  const { startLoading } = useMode();
  const [query] = useQueryParams({
    otherImprovementObjectType: NumberParam,
  });

  const { createCard, fetchPreviewCard } = props;
  useEffect(() => {
    const { typeId, startDate } = paramsUrl;

    setTimeout(() => {
      if (paramsUrl?.actions?.haveParent) {
        createCard(typeId, startDate, {
          ...query,
          parentTypeId: parentCard.typeId,
        });
        fetchPreviewCard(typeId, startDate);
      } else {
        createCard(typeId, startDate, query);
        fetchPreviewCard(typeId, startDate);
      }
    }, 0);

    // eslint-disable-next-line
  }, [query, paramsUrl, createCard, fetchPreviewCard, isSuccessParent]);

  /**
   * @deprecated
   * @see используй navigate
   */
  const history = { push: navigate };
  const parentInfo = {
    ...parentCard,
    isChild: paramsUrl.actions.haveParent,
  };

  /**
   * Навигация с запуском лоудера для отображения уведомления о не сохраненных данных.
   *
   * @param {string} url - Url.
   * @returns {void}
   */
  const navigateWithLading = (url) => {
    startLoading();
    setTimeout(() => {
      navigate(url);
    }, 0);
  };

  return (
    <OghCard
      {...props}
      {...{
        history,
        location,
        navigate,
        navigateWithLading,
        params: { id: paramsUrl.typeId },
        paramsUrl,
        parentInfo,
        typeId: paramsUrl.typeId,
        updateTree: removeTree,
      }}
    />
  );
};

Create.propTypes = propTypes;

export default flow(
  connect(mapStateToProps, mapDispatchToProps),
  withMap,
)(CreateHOC);
