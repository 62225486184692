import { useEditByStateIdAndCreatedFromExternalSystem } from 'app/components/card/ogh/index/card/hooks/useEditByStateIdAndCreatedFromExternalSystem';
import { useUser } from 'app/pages/providers';
import { useState } from 'react';

import { checkPrivileges } from '../../helpers/checkPrivileges';
import { useCheckShowChangeBtn } from './useCheckShowChangeBtn';

interface useSetupChangeButtonI {
  activeButton: boolean;
  typeCode: string | number;
}

/**
 * Setup Кнопки изменения состава паспортов дочерних реестров.
 *
 * @param props - Параметры хука.
 * @param props.activeButton - Active Button.
 * @param props.typeCode - Code type.
 * @returns
 */
export const useSetupChangeButton = ({
  activeButton,
  typeCode,
}: useSetupChangeButtonI) => {
  const [openDialog, setOpenDialog] = useState(false);
  const { user } = useUser();
  const showChangeBtn = useCheckShowChangeBtn();
  const isEditByStateIdAndCreatedFromExternalSystem =
    useEditByStateIdAndCreatedFromExternalSystem();

  const isShow =
    isEditByStateIdAndCreatedFromExternalSystem &&
    showChangeBtn &&
    activeButton &&
    checkPrivileges(user.privileges ?? '', typeCode, 'editOgh');

  /**
   * Функция открывающая диалоговое окно.
   *
   */
  const openDialogHandler = () => {
    setOpenDialog(true);
  };

  /**
   * Функция закрывающая диалоговое окно.
   *
   */
  const closeDialogHandler = () => {
    setOpenDialog(false);
  };

  return { closeDialogHandler, isShow, openDialog, openDialogHandler };
};
