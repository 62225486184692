import { post } from 'app/api';

 
/**
 * Метод получения данных таба "Карта".
 *
 * @param {object} query - Параметры запроса.
 * @returns {Promise<*>} - Возвращает данные таба Карты.
 * @example
 * const { data = [], isLoading } = useQueryAdaptor(
 *     [
 *       'getMapTable',
 *       {
 *         etalon: etalon.id,
 *         copy: copy.id,
 *         typeId,
 *       },
 *     ],
 *     getMapTable,
 *   );
 */
export const getDataMapDiff = (query) => {
  return post('/versions/geodiff', query);
};
