import {
  ANNUAL_VASE_AREA,
  ANNUALS_AREA,
  APOTHECARY_GARDEN,
  BIENNIALS_AREA,
  BULBOUS_AREA,
  CARPET_PLANT_AREA,
  FLINDERS_AREA,
  FLOOR_VERTICAL_DESIGN_AREA,
  FLOOR_VERTICAL_DESIGN_QTY,
  FLOWERS_VERTICAL_DESIGN_AREA,
  FLOWERS_VERTICAL_DESIGN_QTY,
  GREEN_NUM,
  INERT_AREA,
  INERT_OTHER_AREA,
  INERT_STONE_CHIPS_AREA,
  INERT_TREE_BARK_AREA,
  LAWN_AREA,
  PERENNIALS_AREA,
  ROCKERY_AREA,
  SEASON_SHIFT_AREA,
  SECTION_NUM,
  SHRUBS_AREA,
  SUSPEND_VERTICAL_DESIGN_AREA,
  SUSPEND_VERTICAL_DESIGN_QTY,
  TOTAL_AREA,
  TULIP_AREA,
  VASE_QTY,
} from 'app/constants/fieldNames';
import { SHRUBS_HINT } from 'app/constants/messages';
import { OZN, SPA } from 'app/constants/oghTypes';
import { RANDOM_NUMBER } from 'core/forTesting/constants';
import { TOTAL_AREA_GEO_NAME } from 'core/uiKit/preparedInputs/textField/TotalAreaGeo';
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import { CharacteristicsTab } from './CharacteristicsTab';
import { useSetupCharacteristicsTab } from './useSetup.CharacteristicsTab';

vi.mock('./useSetup.CharacteristicsTab');

describe('CharacteristicsTabSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: RANDOM_NUMBER,
      required: true,
      setCalculatedField: vi.fn(),
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert
    expect(wrapper.text()).toBe('<FieldsSplitColumns />');
  });
  it('№ участка', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: RANDOM_NUMBER,
      required: true,
      setCalculatedField: vi.fn(),
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert
    expect(wrapper.find('NumberFieldRF').at(0).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 0,
      disabled: true,
      label: '№ участка',
      maxLength: 3,
      name: SECTION_NUM,
      required: true,
    });
  });
  it('№ цветника', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: OZN,
      required: true,
      setCalculatedField: vi.fn(),
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert
    expect(wrapper.find('NumberFieldRF').at(1).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 0,
      disabled: true,
      hidden: false,
      label: '№ цветника',
      maxLength: 15,
      name: GREEN_NUM,
      required: true,
    });
  });
  it('Общая площадь цветника, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: OZN,
      required: true,
      setCalculatedField: vi.fn(),
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert
    expect(wrapper.find('NumberFieldRF').at(2).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Общая площадь цветника, кв.м',
      maxLength: 16,
      name: TOTAL_AREA,
    });
  });
  it('Площадь объекта по пространственным координатам, кв.м.', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)

    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: OZN,
      required: true,
      setCalculatedField: vi.fn(),
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert
    expect(wrapper.find('NumberFieldRF').at(3).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Площадь объекта по пространственным координатам, кв.м.',
      maxLength: 16,
      name: TOTAL_AREA_GEO_NAME,
    });
  });
  it('Однолетники, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: OZN,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert
    const { withChange } = wrapper.find('NumberFieldRF').at(4).props();
    withChange(RANDOM_NUMBER);
    expect(wrapper.find('NumberFieldRF').at(4).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Однолетники, кв.м',
      maxLength: 11,
      name: ANNUALS_AREA,
      withChange: expect.any(Function),
    });

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      TOTAL_AREA,
      ANNUALS_AREA,
      RANDOM_NUMBER,
    );
    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      SEASON_SHIFT_AREA,
      ANNUALS_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Однолетники в вазонах, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: OZN,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(5).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: false,
      hidden: true,
      label: 'Однолетники в вазонах, кв.м',
      maxLength: 11,
      name: ANNUAL_VASE_AREA,
    });
  });
  it('Кустовые и корневищные многолетники, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(6).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(6).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Кустовые и корневищные многолетники, кв.м',
      maxLength: 11,
      name: PERENNIALS_AREA,
      withChange: expect.any(Function),
    });

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      TOTAL_AREA,
      PERENNIALS_AREA,
      RANDOM_NUMBER,
    );
    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      SEASON_SHIFT_AREA,
      PERENNIALS_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Многолетники и розы, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: OZN,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(6).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(6).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Многолетники и розы, кв.м',
      maxLength: 11,
      name: PERENNIALS_AREA,
      withChange: expect.any(Function),
    });

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      TOTAL_AREA,
      PERENNIALS_AREA,
      RANDOM_NUMBER,
    );
    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      SEASON_SHIFT_AREA,
      PERENNIALS_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Инертный материал (отсыпка), кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: OZN,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(7).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(7).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Инертный материал (отсыпка), кв.м',
      maxLength: 11,
      name: INERT_AREA,
      withChange: expect.any(Function),
    });

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      TOTAL_AREA,
      INERT_AREA,
      RANDOM_NUMBER,
    );
    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      SEASON_SHIFT_AREA,
      INERT_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Инертный материал (отсыпка), каменная крошка, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: OZN,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(8).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(8).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: false,
      hidden: false,
      label: 'Инертный материал (отсыпка), каменная крошка, кв.м',
      maxLength: 11,
      name: INERT_STONE_CHIPS_AREA,
      withChange: expect.any(Function),
    });

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      INERT_AREA,
      INERT_STONE_CHIPS_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Инертный материал (отсыпка), древесная кора (щепа), кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: OZN,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(9).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(9).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: false,
      hidden: false,
      label: 'Инертный материал (отсыпка), древесная кора (щепа), кв.м',
      maxLength: 11,
      name: INERT_TREE_BARK_AREA,
      withChange: expect.any(Function),
    });

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      INERT_AREA,
      INERT_TREE_BARK_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Инертный материал (отсыпка), иное, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: OZN,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(10).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(10).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: false,
      hidden: false,
      label: 'Инертный материал (отсыпка), иное, кв.м',
      maxLength: 11,
      name: INERT_OTHER_AREA,
      withChange: expect.any(Function),
    });

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      INERT_AREA,
      INERT_OTHER_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Ковровые растения, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(11).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(11).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Ковровые растения, кв.м',
      maxLength: 11,
      name: CARPET_PLANT_AREA,
      withChange: expect.any(Function),
    });

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      TOTAL_AREA,
      CARPET_PLANT_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Декоративные кустарники (как элемент цветника), кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(12).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(12).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: false,
      helperText: SHRUBS_HINT,
      hidden: false,
      label: 'Декоративные кустарники (как элемент цветника), кв.м',
      maxLength: 11,
      name: SHRUBS_AREA,
      withChange: expect.any(Function),
    });

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      TOTAL_AREA,
      SHRUBS_AREA,
      RANDOM_NUMBER,
    );
    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      SEASON_SHIFT_AREA,
      SHRUBS_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Аптекарский огород, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(13).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(13).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Аптекарский огород, кв.м',
      maxLength: 11,
      name: APOTHECARY_GARDEN,
      withChange: expect.any(Function),
    });

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      TOTAL_AREA,
      APOTHECARY_GARDEN,
      RANDOM_NUMBER,
    );
  });
  it('С учетом сезонной сменности, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);

    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(14).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'С учетом сезонной сменности, кв.м',
      maxLength: 16,
      name: SEASON_SHIFT_AREA,
    });
  });
  it('Луковичные и клубнелуковичные кроме тюльпанов, нарциссов, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(15).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(15).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: false,
      hidden: false,
      label: 'Луковичные и клубнелуковичные кроме тюльпанов, нарциссов, кв.м',
      maxLength: 11,
      name: BULBOUS_AREA,
      withChange: expect.any(Function),
    });
    expect(setCalculatedFieldSpy).toBeCalledWith(
      SEASON_SHIFT_AREA,
      BULBOUS_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Тюльпаны, нарциссы, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(16).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(16).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: false,
      hidden: false,
      label: 'Тюльпаны, нарциссы, кв.м',
      maxLength: 11,
      name: TULIP_AREA,
      withChange: expect.any(Function),
    });
    expect(setCalculatedFieldSpy).toBeCalledWith(
      SEASON_SHIFT_AREA,
      TULIP_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Двулетники (виола), кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    const { withChange } = wrapper.find('NumberFieldRF').at(17).props();
    withChange(RANDOM_NUMBER);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(17).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: false,
      hidden: false,
      label: 'Двулетники (виола), кв.м',
      maxLength: 11,
      name: BIENNIALS_AREA,
      withChange: expect.any(Function),
    });
    expect(setCalculatedFieldSpy).toBeCalledWith(
      SEASON_SHIFT_AREA,
      BIENNIALS_AREA,
      RANDOM_NUMBER,
    );
  });
  it('Вазоны, шт.', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(18).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Вазоны, шт.',
      maxLength: 11,
      name: VASE_QTY,
    });
  });
  it('Напольные вертикальные конструкции, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(19).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Напольные вертикальные конструкции, кв.м',
      maxLength: 11,
      name: FLOOR_VERTICAL_DESIGN_AREA,
    });
  });
  it('Напольные вертикальные конструкции, шт.', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(20).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 0,
      disabled: true,
      hidden: false,
      label: 'Напольные вертикальные конструкции, шт.',
      maxLength: 11,
      name: FLOOR_VERTICAL_DESIGN_QTY,
    });
  });
  it('Подвесные вертикальные конструкции, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(21).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Подвесные вертикальные конструкции, кв.м',
      maxLength: 11,
      name: SUSPEND_VERTICAL_DESIGN_AREA,
    });
  });
  it('Подвесные вертикальные конструкции, шт.', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(22).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 0,
      disabled: true,
      hidden: false,
      label: 'Подвесные вертикальные конструкции, шт.',
      maxLength: 11,
      name: SUSPEND_VERTICAL_DESIGN_QTY,
    });
  });
  it('Вертикальные конструкции. Иные элементы, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(23).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Вертикальные конструкции. Иные элементы, кв.м',
      maxLength: 11,
      name: FLOWERS_VERTICAL_DESIGN_AREA,
    });
  });
  it('Вертикальные конструкции. Иные элементы, шт.', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(24).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 0,
      disabled: true,
      hidden: false,
      label: 'Вертикальные конструкции. Иные элементы, шт.',
      maxLength: 11,
      name: FLOWERS_VERTICAL_DESIGN_QTY,
    });
  });
  it('Декорирование щепой, кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert

    expect(wrapper.find('NumberFieldRF').at(25).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: false,
      label: 'Декорирование щепой, кв.м',
      maxLength: 11,
      name: FLINDERS_AREA,
    });
  });
  it('Рокарий (многолетние, однолетние), кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert

    const { withChange } = wrapper.find('NumberFieldRF').at(26).props();
    withChange(RANDOM_NUMBER);

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      TOTAL_AREA,
      ROCKERY_AREA,
      RANDOM_NUMBER,
    );
    expect(wrapper.find('NumberFieldRF').at(26).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: true,
      hidden: true,
      label: 'Рокарий (многолетние, однолетние), кв.м',
      maxLength: 11,
      name: ROCKERY_AREA,
      withChange: expect.any(Function),
    });
  });
  it('Газоны (как элемент цветника), кв.м', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const setCalculatedFieldSpy = vi.fn();
    useSetupCharacteristicsTab.mockReturnValue({
      editMode: true,
      isCurrentObjectIsExternalSystem: true,
      parentTypeId: SPA,
      required: true,
      setCalculatedField: setCalculatedFieldSpy,
    });
    // 🔥 Act

    const wrapper = shallow(<CharacteristicsTab />);
    // ❓ Assert
    const { withChange } = wrapper.find('NumberFieldRF').at(27).props();
    withChange(RANDOM_NUMBER);

    expect(setCalculatedFieldSpy).toHaveBeenCalledWith(
      TOTAL_AREA,
      LAWN_AREA,
      RANDOM_NUMBER,
    );
    expect(wrapper.find('NumberFieldRF').at(27).props()).toStrictEqual({
      allowNegative: false,
      dark: false,
      decimalScale: 2,
      disabled: false,
      hidden: true,
      label: 'Газоны (как элемент цветника), кв.м',
      maxLength: 11,
      name: LAWN_AREA,
      withChange: expect.any(Function),
    });
  });
});
