import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { useMapContext } from 'app/components/map/useMapContext';
import { DISTRICT_BOUNDARIES } from 'app/constants/layers';
import ToggleButton from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowDistrictBoundaries/ToggleButton';
import { useGetDistrictBoundaries } from 'app/pages/cardsOgh/components/RightPanelCard/components/Panel/buttons/ShowDistrictBoundaries/useGetDistrictBoundaries';
import React, { useState } from 'react';

 
/**
 * Компонент кнопки для edit панели в списке объектов.
 *
 * @returns {React.ReactComponentElement} - Компонет кнопки.
 * @example <ShowDistrictBoundaries/>
 */
export const ShowDistrictBoundaries = () => {
  const { clearLayer, getGeometry } = useMapContext();
  const [hasDistrictBoundaries, setHasDistrictBoundaries] = useState(false);

  const { getDistrictBoundaries } = useGetDistrictBoundaries(
    setHasDistrictBoundaries,
  );
  const { recordId } = useGetCurrentCard();

   
  /**
   * Функция переключения ToggleButton из неактивновго в активное и наоборот.
   *
   * @returns {void} - Nothing.
   * @example <ToggleButton
   *       active={hasDistrictBoundaries}
   *       onClick={checkDistrictBoundaries}
   *     />
   */
  const checkDistrictBoundaries = () => {
    if (hasDistrictBoundaries) {
      clearLayer(DISTRICT_BOUNDARIES);
      setHasDistrictBoundaries(false);
    } else {
      const data = {
        geometry: getGeometry(recordId),
      };
      getDistrictBoundaries(data);
    }
  };

  return (
    <ToggleButton
      active={hasDistrictBoundaries}
      onClick={checkDistrictBoundaries}
    />
  );
};
