import { CheckboxRF } from 'core/form/reduxForm/fields/default';
import React from 'react';

export const NO_CALC_NAME = 'no_calc';

/**
 * Checkbox RF __Не учитывать__.
 *
 * @param props - Пропсы.
 * @param props.disabled - Флаг возможности редактирования.
 * @param props.label - Лейбл если есть, по умолчанию "Не учитывать".
 * @returns
 */
export const NoCalcRF = (props: { disabled: boolean; label?: string }) => {
  return (
    <CheckboxRF
      {...props}
      name={NO_CALC_NAME}
      label={props.label || 'Не учитывать'}
    />
  );
};
