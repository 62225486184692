import React from 'react';

import Icon from './Icon';

/**
 * Кнопка ShowDistrictBoundaries.
 *
 * @param {{onclick: Function, active: boolean}} props - Компонент кнопки.
 * @returns {React.ReactComponentElement} - Жопа.
 * @example <ToggleButton
 *       active={hasDistrictBoundaries}
 *       onClick={checkDistrictBoundaries}
 *     />
 */
const ToggleButton = ({ onClick, active }) => {
  const title = active ? 'Скрыть границы районов' : 'Показать границы районов';

  return (
    <button
      className={'button-toggle'}
      title={title}
      type={'button'}
      onClick={onClick}
    >
      <Icon active={active} />
    </button>
  );
};

export default ToggleButton;
