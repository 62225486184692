import { post } from 'app/api';

 
/**
 * Метод получения границ районов.
 *
 * @param {object} data - Принимает в себя текущую геометрию.
 * @returns {Promise<*>} - Возвращает данные геометрии и описание.
 * @example ---
 */
export const postDistrictBoundaries = (data) =>
  post('/ogh/geometry/district/intersection', data);
