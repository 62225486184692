import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { RANDOM_BOOL } from 'core/forTesting/constants';
import { vi } from 'vitest';

import { useSetupImprovementObjectCategoryIdFieldOzn } from './useSetup.ImprovementObjectCategoryId.Field.Ozn';

vi.mock('app/api/hooks/useGetParentCard');
vi.mock('app/pages/cardsOgh/hooks/useMode');

/**
 * Mock Default.
 *
 * @returns {void}
 */
const mockDefault = () => {
  useGetParentCard.mockReturnValue({});
  useMode.mockReturnValue({});
};

describe('🐛 spec useSetup.ImprovementObjectCategoryId.Field.Ozn', () => {
  it('🧪 default', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    // 🧹 clear mock

    //🔥 Act
    const res = useSetupImprovementObjectCategoryIdFieldOzn();

    //❓ Assert
    expect(res).toStrictEqual({
      editMode: undefined,
      filter: expect.any(Function),
    });
  });
  it('🧪 editMode', () => {
    expect.hasAssertions();
    // ☣️  Arrange (всякие моки)
    mockDefault();

    useMode.mockReturnValue({ editMode: RANDOM_BOOL });
    // 🧹 clear mock

    //🔥 Act
    const res = useSetupImprovementObjectCategoryIdFieldOzn();

    //❓ Assert
    expect(res).toStrictEqual({
      editMode: RANDOM_BOOL,
      filter: expect.any(Function),
    });
  });
});
