// 29 Закрытые велопарковки для долгосрочного хранения велосипедов
import {
  fetchOghChoiceAddressList,
  resetOghChoiceAddressList,
} from 'app/actions/odsObjectActions';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute } from 'app/components/card/common/getParseAttribute';
import { parseDate } from 'app/components/card/common/parse';
import { objectTabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import { IsDiffHeightMarkField } from 'app/components/card/ogh/components/fields/checkBoxFields/IsDiffHeightMark.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import { STORAGE_PLACE } from 'app/constants/oghTypes';
import { column } from 'app/constants/tables';
import { CharacteristicsTabStoragePlace } from 'app/pages/cardsOgh/StoragePlace/tabs/Characteristics.Tab.StoragePlace';
import getTreeParentId from 'app/selectors/card/getTreeParentId';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import getDict from 'app/selectors/getDict';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { TabsCard } from 'core/uiKit/components/TabsCard';
import { TabsCardItem } from 'core/uiKit/components/TabsCard/TabsCardItem';
import { COATING_GROUP_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingGroupId';
import { COATING_TYPE_ID_NAME } from 'core/uiKit/preparedInputs/selects/simpleSelect/CoatingTypeId';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import {
  elementFormGrid,
  renderAbutmentList,
  renderMafList,
} from '../common/grid';
import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const types = ['tech_place', 'storage_place'];
const Versions = VersionsTemplate(types);

const getMafTypeLevel1 = getDict('mafTypeLevel1');
const getMafTypeLevel2 = getDict('mafTypeLevel2');
const getMafTypeLevel3 = getDict('mafTypeLevel3');

/**
 * Классовый компонет StoragePlaceCard.
 */
class StoragePlaceCard extends React.Component {

  /**
   * Компонент жизненого цикла componentDidMount.
   *
   * @returns {void} - Nothing.
   * @example --------
   */
  componentDidMount() {
    const { editMode, treeParentId } = this.props;
    if (editMode && treeParentId) {
      this.props.fetchOghChoiceAddressList(treeParentId);
    }
  }

  /**
   * Компонент жизненого цикла componentDidUpdate.
   *
   * @returns {void} - Nothing.
   * @param {object} prevProps - Предыдущие пропсы.
   * @example --------
   */
  componentDidUpdate(prevProps) {
    const { editMode, treeParentId } = this.props;
    if (editMode !== prevProps.editMode) {
      if (editMode && treeParentId) {
        this.props.fetchOghChoiceAddressList(treeParentId);
      }
    }
  }

  /**
   * Компонент жизненого цикла componentWillUnmount.
   *
   * @returns {void} - Nothing.
   * @example ----
   */
  componentWillUnmount() {
    this.props.resetOghChoiceAddressList();
  }

  /**
   * Метод жизненого цикла render().
   *
   * @returns {JSX.Element} - Реакт компонент.
   * @example ----
   */
  render() {
    const { props } = this;
    const {
      card,
      mode,
      required,
      editMode,
      bikeRefTypes,
      isEditCurrentObjectIsExternalSystem,
    } = props;
    const { type_id: typeId } = card;

    const tabsEnd = [
      {
        label: 'Исходные данные',
        value: 'characteristics',
      },
      {
        label: 'Элемент сопряжения',
        show: !editMode,
        value: 'abutments',
      },
      {
        label: 'МАФ',
        value: 'mafs',
      },
      {
        label: 'Документы',
        value: 'documents',
      },
    ];

    return (
      <CardAbstract>
        <CardContainer>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(typeId)}

            <div id="props">
              <FieldsSplitColumns>
                <SelectRF
                  name={'bike_park_type_id'}
                  label={'Тип велопарковки'}
                  options={card.bike_park_type}
                  required={isMandatory(
                    card.type_id,
                    'bike_park_type_id',
                    required,
                  )}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                />
                <SelectRF
                  name={'bike_ref_type_id'}
                  label={'Уточнение типа'}
                  options={bikeRefTypes}
                  required={isMandatory(
                    card.type_id,
                    'bike_ref_type_id',
                    required,
                  )}
                  disabled={!editMode}
                />
              </FieldsSplitColumns>

              <ParentInfo />
              <IsDiffHeightMarkField />

              <TabsCard tabs={tabsEnd}>
                <TabsCardItem tabValue={'characteristics'}>
                  <CharacteristicsTabStoragePlace />
                </TabsCardItem>
                <TabsCardItem tabValue={'abutments'}>
                  {elementFormGrid(
                    [
                      {
                        addition: { abutmentTypes: this.props.abutmentTypes },
                        component: renderAbutmentList,
                        editable: false,
                        formValue: true,
                        id: 'abutment_type_list',
                        name: 'abutment_type_list',
                        style: { overflow: 'auto' },
                      },
                    ],
                    column.ONE,
                    '',
                    typeId,
                  )}
                </TabsCardItem>
                <TabsCardItem tabValue={'mafs'}>
                  {elementFormGrid(
                    [
                      {
                        addition: {
                          mafTypeLevel1: this.props.mafTypeLevel1,
                          mafTypeLevel2: this.props.mafTypeLevel2,
                          mafTypeLevel3: this.props.mafTypeLevel3,
                        },
                        component: renderMafList,
                        editable:
                          editMode && isEditCurrentObjectIsExternalSystem,
                        formValue: true,
                        id: 'maf_type_list',
                        name: 'maf_type_list',
                      },
                    ],
                    column.ONE,
                    '',
                    typeId,
                  )}
                </TabsCardItem>
                <TabsCardItem tabValue={'documents'}>
                  <OtherDocumentsRF />
                </TabsCardItem>
              </TabsCard>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={props.createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardContainer>
      </CardAbstract>
    );
  }
}

const getAbutmentTypes = getDict('abutmentTypes');
const getBikeRefTypes = getDict('bikeRefTypes');
const storagePlaceDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

const numericFields = [
  {
    name: 'area',
    positive: true,
    type: 'decimal',
  },
];

const dictFields = [
  'bike_park_type_id',
  'bike_ref_type_id',
  'material_id',
  'abutment_type_id',
  'maf_type_id',
  COATING_GROUP_ID_NAME,
  COATING_TYPE_ID_NAME,
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object} - Отвалидированные поля.
 * @example ----
 */
const validate = (values) => validateNumeric(values, numericFields);

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {object} - Возвращают атрибуты.
 * @example ----
 */
const createAttribute = (formValues) => ({
  ...createDocumentsAttribute(formValues),
});

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения из формы.
 * @returns {void}
 */
function onSubmit(formValues) {
  const values = { ...formValues };

  delete values.parent_name;

  const formValuesWithAttribute = {
    ...values,
    ...createAttribute(values),
  };

  validateRequiredFieldsAsync(
    formValuesWithAttribute,
    mandatoryFields[this.props.card.type_id],
  );
  formValuesWithAttribute.is_diff_height_mark = Boolean(
    formValuesWithAttribute.is_diff_height_mark,
  );

  if (_.get(formValuesWithAttribute, 'abutment_type_list.table[0]')) {
    formValuesWithAttribute.abutment_type_list =
      formValuesWithAttribute.abutment_type_list.table;
  } else {
    delete formValuesWithAttribute.abutment_type_list;
  }

  if (_.get(formValuesWithAttribute, 'maf_type_list.table[0]')) {
    formValuesWithAttribute.maf_type_list =
      formValuesWithAttribute.maf_type_list.table;
  } else {
    delete formValuesWithAttribute.maf_type_list;
  }

  submitCard(
    this.props,
    transformValues(formValuesWithAttribute, {
      custom: {
        address_list: {

          /**
           * Метод transformer.
           *
           * @param {* | null} value - Значение.
           * @returns {Array | null}
           */
          transformer: (value) => {
            return value
              ? value.map((item) => {
                  return _.mapValues(
                    _.pick(item, [
                      'okrug_id',
                      'address_id',
                      'street_id',
                      'district_id',
                      'description',
                    ]),
                    (id) => {
                      return { bti_object: id };
                    },
                  );
                })
              : null;
          },
        },
      },
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция formatAddressOption.
 *
 * @param {object} item - Хопа.
 * @returns {object} - Хопа.
 */
const formatAddressOption = (item) => {
  const name = `${item.okrug_name} / ${item.district_name} / ${item.street_name} / ${item.address_name}`;
  return {
    id: item.address_id,
    name,
  };
};

/**
 * Функция получения и установки изначальных значений из State.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object} - Поля.
 * @example ----
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);

  const btiAddresses = state.card.choiceAddressList;
  const formattedBtiAddresses = btiAddresses
    ? btiAddresses.map(formatAddressOption)
    : [];

  const result = {
    abutmentTypes: getAbutmentTypes(state),
    bikeRefTypes: getBikeRefTypes(state),
    btiAddresses,
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),
    enableReinitialize: true,
    formValues: getFormValues(state),
    formattedBtiAddresses,

    initialValues: {
      abutment_type_list: {
        showDialog: false,
        table: parse('abutment_type_list'),
      },
      address_list:
        (props.card.address_list &&
          props.card.address_list.map((item) => {
            return {
              ...item,
              ...item.attributes,
              attributes: null,
            };
          })) ||
        null,
      area: parse('area'),
      bike_park_type_id: parse(
        'bike_park_type_id.bike_park_type',
        STORAGE_PLACE,
      ),
      bike_ref_type_id: parse('bike_ref_type_id.bike_ref_type', STORAGE_PLACE),
      coating_group_id: parse('coating_group_id.coating_group'),
      coating_type_id: parse('coating_type_id.coating_type'),
      endDate: parseDate(props.card.end_date),
      is_diff_height_mark: parse('is_diff_height_mark'),
      maf_type_list: { showDialog: false, table: parse('maf_type_list') },
      material_id: parse('material_id.material'),
      no_calc: parse('no_calc'),
      parent_name: props.card.parent_info,
      placement_name: parse('placement_name'),
      startDate: parseDate(props.card.start_date),
      totalAreaGeo: parse('total_area_geo'),
    },

    mafTypeLevel1: getMafTypeLevel1(state),
    mafTypeLevel2: getMafTypeLevel2(state),
    mafTypeLevel3: getMafTypeLevel3(state),
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    treeParentId: getTreeParentId(state, props),
    validate,
  };

  return storagePlaceDocumentsReducer(result, state, props);
};

const mapDispatchToProps = {
  fetchOghChoiceAddressList,
  resetOghChoiceAddressList,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(StoragePlaceCard));
