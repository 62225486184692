/* eslint-disable  */
import { shallow } from 'enzyme';
import { vi } from 'vitest';

import StoragePlaceCard from './storage_place';

vi.mock('dateformat', () => ({
  DateFormat: vi.fn(),
}));

vi.mock('redux-form', async (importOriginal) => {
  const mod = await importOriginal();
  return {
    ...mod,
    reduxForm: vi.fn(() => (Component) => {
      return Component;
    }),
  };
});

vi.mock('react-redux', () => ({
  ...vi.importActual('react-redux'),
  connect: vi.fn(() => (b) => {
    return b;
  }),
}));

describe('StoragePlaceSpec', () => {
  it('default', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { type_id: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };
    // 🔥 Act
    const wrapper = shallow(<StoragePlaceCard {...props} />);

    // ❓ Assert
    expect(wrapper.text()).toBe('<CardAbstract />');
  });
  it('🧪 есть таба CharacteristicsTabStoragePlace', () => {
    expect.hasAssertions();
    // ☣️ Arrange (всякие моки)
    const props = {
      card: { type_id: 1 },
      editMode: true,
      formValues: {
        bord_begin: 1,
      },
      isCurrentObjectIsExternalSystem: false,
      mode: {},
    };
    // 🔥 Act
    const wrapper = shallow(<StoragePlaceCard {...props} />);

    // ❓ Assert
    expect(wrapper.find('CharacteristicsTabStoragePlace').length).toBe(1);
  });
});
