import { useGetParentCard } from 'app/api/hooks/useGetParentCard';
import {
  ANNUALS_AREA,
  APOTHECARY_GARDEN,
  BIENNIALS_AREA,
  BULBOUS_AREA,
  CARPET_PLANT_AREA,
  INERT_AREA,
  INERT_OTHER_AREA,
  INERT_STONE_CHIPS_AREA,
  INERT_TREE_BARK_AREA,
  LAWN_AREA,
  PERENNIALS_AREA,
  ROCKERY_AREA,
  SEASON_SHIFT_AREA,
  SHRUBS_AREA,
  TOTAL_AREA,
  TULIP_AREA,
} from 'app/constants/fieldNames';
import { useMode } from 'app/pages/cardsOgh/hooks/useMode';
import { isInteger } from 'app/utils/mathUtils';
import { get } from 'lodash';

const areaCalcDict = {
  [INERT_AREA]: [
    INERT_STONE_CHIPS_AREA,
    INERT_OTHER_AREA,
    INERT_TREE_BARK_AREA,
  ],
  [SEASON_SHIFT_AREA]: [
    ANNUALS_AREA,
    PERENNIALS_AREA,
    INERT_AREA,
    SHRUBS_AREA,
    BULBOUS_AREA,
    TULIP_AREA,
    BIENNIALS_AREA,
  ],
  [TOTAL_AREA]: [
    ANNUALS_AREA,
    PERENNIALS_AREA,
    INERT_AREA,
    SHRUBS_AREA,
    APOTHECARY_GARDEN,
    CARPET_PLANT_AREA,
    ROCKERY_AREA,
    LAWN_AREA,
  ],
};

/**
 * Setup Таб Цветников __Исходные данные__.
 *
 * @param {object} props - Пропсы.
 * @returns { {editMode: boolean, isCurrentObjectIsExternalSystem: boolean, parentTypeId: number,required: boolean,setCalculatedField: Function}}
 */
export const useSetupCharacteristicsTab = (props) => {
  const { typeId: parentTypeId } = useGetParentCard();
  const { editMode } = useMode();
  const { isCurrentObjectIsExternalSystem, required } = props;

  /**
   * Функция установки вычисляемых значений setCalculatedField.
   *
   * @param {string} calculatedFieldName - Имя.
   * @param {string} changedFieldName - Имя изменяемого поля.
   * @param {string | *} value - Значение.
   * @returns {void}
   */
  const setCalculatedField = (calculatedFieldName, changedFieldName, value) => {
    const { changeFieldValue } = props;

    changeFieldValue(
      calculatedFieldName,
      calcArea(calculatedFieldName, {
        [changedFieldName]: value,
      }),
    );
  };

  /**
   * Функция calcArea.
   *
   * @param {string} fieldName - Имя.
   * @param {Function} changedValue - Функция изменения значения.
   * @returns {null|*|string}
   */
  const calcArea = (fieldName, changedValue = {}) => {
    if (!areaCalcDict[fieldName]) {
      return null;
    }

    const values = Object.assign({}, props.values, changedValue);
    const total = areaCalcDict[fieldName].reduce((result, key) => {
      return +result + +get(values, key, 0);
    }, 0);

    return isInteger(total) ? total : total.toFixed(2);
  };

  return {
    editMode,
    isCurrentObjectIsExternalSystem,
    parentTypeId,
    required,
    setCalculatedField,
  };
};
