// 15 Бортовой каменьimport CardAbstract from 'app/components/card/common/CardAbstract';
import CardAbstract from 'app/components/card/common/CardAbstract';
import { getParseAttribute, parseDate } from 'app/components/card/common/parse';
import { objectTabs, Tabs } from 'app/components/card/common/tabs';
import {
  validateNumeric,
  validateRequiredFieldsAsync,
} from 'app/components/card/common/validation';
import validateMaterial from 'app/components/card/common/validation/validateMaterial';
import { BordBeginField } from 'app/components/card/ogh/components/fields/numberFields/BordBegin.Field';
import { BordEndField } from 'app/components/card/ogh/components/fields/numberFields/BordEnd.Field';
import { DistanceField } from 'app/components/card/ogh/components/fields/numberFields/Distance.Field';
import { OdhSideIdField } from 'app/components/card/ogh/components/fields/selectFields/OdhSideId.Field';
import { DescriptionField } from 'app/components/card/ogh/components/fields/textFields/Description.Field';
import { OdhAxisField } from 'app/components/card/ogh/components/fields/textFields/OdhAxis.Field';
import {
  createAttribute as createDocumentsAttribute,
  mapStateToPropsReducer as documentsReducer,
} from 'app/components/card/ogh/DocumentsTab';
import getBoundStoneMaterial from 'app/selectors/card/getBoundStoneMaterial/getBoundStoneMaterial';
import getDefaultFieldValues from 'app/selectors/card/getDefaultFieldValues';
import isCreateMode from 'app/selectors/card/isCreateMode';
import isEditMode from 'app/selectors/card/isEditMode';
import isRequired from 'app/selectors/card/isRequired';
import getFormValues from 'app/selectors/form/getFormValues';
import filterDictBySprNumber from 'app/utils/card/filterDictBySprNumber';
import isMandatory, { mandatoryFields } from 'app/utils/card/isMandatory';
import { transformValues } from 'app/utils/form/transformation';
import { CheckboxRF } from 'core/form/reduxForm/fields';
import { SelectRF } from 'core/form/reduxForm/fields/default/selects/SelectRF';
import { TextFieldRF } from 'core/form/reduxForm/fields/default/TextFieldRF';
import { OtherDocumentsRF } from 'core/form/reduxForm/fields/prepared/tables/documentTables/OtherDocuments.RF';
import { CardContainer } from 'core/uiKit/components/card.components/CardContainer';
import { FieldsSplitColumns } from 'core/uiKit/components/card.components/FieldsSplitColumns';
import { GroupContainer } from 'core/uiKit/components/card.components/GroupContainer';
import { ParentInfo } from 'core/uiKit/preparedInputs/textField/ParentInfo';
import { find } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { change, reduxForm } from 'redux-form';

import printOghCardHeader from './header/oghHeader';
import submitCard from './submit';
import VersionsTemplate from './versions/VersionsTemplate';

const BOUND_STONE_MARK_NO_GOST = 10163; // не по ГОСТу

const types = ['board_stone'];
const Versions = VersionsTemplate(types);

const tabs = [
  { id: 'characteristics-tab', name: 'Исходные данные' },
  { id: 'docs-tab', name: 'Документы' },
];

/**
 * Компонент __Бортовые камни__.
 *
 * @returns {JSX.Element}
 */
class BoardStoneCard extends React.Component {

  /**
   * Метод жизненного цикла componentDidMount.
   *
   * @returns {void}
   */
  componentDidMount() {
    const { editMode } = this.props;
    if (editMode) {
      this.handleMaterialFieldChange();
    }
  }

  /**
   * Метод жизненного цикла componentDidUpdate.
   *
   * @param {object} prevProps - Предыдущие пропсы.
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    const {
      editMode,
      formValues: { bound_stone_mark_id },
    } = this.props;

    if (
      editMode &&
      bound_stone_mark_id !== prevProps.formValues.bound_stone_mark_id
    ) {
      this.handleMaterialFieldChange();
    }
  }

  /**
   * Метод getBoundStoneMarkOptions.
   *
   * @returns {Array<string>}
   */
  getBoundStoneMarkOptions() {
    const { type_id, bound_stone_mark, viewing } = this.props.card;

    if (viewing) {
      return bound_stone_mark;
    }
    return filterDictBySprNumber(bound_stone_mark, type_id);
  }

  /**
   * Метод getBoundStoneMarkMaterial.
   *
   * @returns {Array<string>}
   */
  getBoundStoneMarkMaterial() {
    const {
      card,
      formValues: { bound_stone_mark_id },
    } = this.props;
    const { bound_stone_mark, material } = card;

    const currentBoundStoneMark = find(bound_stone_mark, {
      id: bound_stone_mark_id,
    });
    const currentBoundStoneMarkMaterial = find(material, {
      id: currentBoundStoneMark.material_id,
    });

    return currentBoundStoneMarkMaterial.name || null;
  }

  /**
   * Метод handleMaterialFieldChange.
   *
   * @returns {void}
   */
  handleMaterialFieldChange() {
    const {
      formValues: { bound_stone_mark_id },
      changeFieldValue,
    } = this.props;

    if (
      !bound_stone_mark_id ||
      bound_stone_mark_id === BOUND_STONE_MARK_NO_GOST
    ) {
      changeFieldValue('material_id', null);
    } else {
      const value = this.getBoundStoneMarkMaterial();
      changeFieldValue('material_id', value);
    }
  }

  /**
   * Метод isBoundStoneMarkNoGost.
   *
   * @returns {boolean}
   */
  isBoundStoneMarkNoGost() {
    const {
      formValues: { bound_stone_mark_id },
    } = this.props;

    if (
      !bound_stone_mark_id ||
      bound_stone_mark_id === BOUND_STONE_MARK_NO_GOST
    ) {
      return true;
    }
    return false;
  }

  /**
   * Метод renderMaterialInput.
   *
   * @returns {React.ReactElement}
   */
  renderMaterialInput() {
    const { card, editMode, viewing } = this.props;

    const { material } = card;

    if (this.isBoundStoneMarkNoGost() && !viewing) {
      // https://jira.elocont.ru/browse/ODS-7132
      //  id=10163, code=185,
      // 7	granite2	Гранит
      // 1	concrete	Бетонный
      const granite = material.find(({ id }) => +id === 100900) || {};
      const concrete = material.find(({ id }) => +id === 82) || {};
      const options = [granite, concrete].map((item) => ({
        id: item.name,
        name: item.name,
      }));

      return (
        <SelectRF
          label={'Материал'}
          name={'material_id'}
          disabled={!editMode}
          options={options}
        />
      );
    }
    return (
      <TextFieldRF
        label={'Материал'}
        name={'material_id'}
        disabled={!editMode}
      />
    );
  }

  /**
   * Метод render.
   *
   * @returns {JSX.Element}
   */
  render() {
    const {
      card,
      mode,
      createMode,
      editMode,
      required,
      isEditCurrentObjectIsExternalSystem,
    } = this.props;

    const materialInput = this.renderMaterialInput();

    return (
      <CardContainer>
        <CardAbstract>
          <div id="editor-card">
            {printOghCardHeader(card, mode)}
            {card && objectTabs(card.type_id)}

            <div id="props">
              <FieldsSplitColumns>
                <SelectRF
                  label={'Тип'}
                  name={'flat_element_type_id'}
                  options={card.flat_element_type}
                  disabled={!(editMode && isEditCurrentObjectIsExternalSystem)}
                  required={isMandatory(
                    card.type_id,
                    'flat_element_type_id',
                    required,
                  )}
                />
                <ParentInfo />
              </FieldsSplitColumns>

              <Tabs tabs={tabs} />

              <div id="characteristics">
                <GroupContainer title={'Местоположение'}>
                  <FieldsSplitColumns>
                    <OdhAxisField />
                    <OdhSideIdField />
                    <BordBeginField />
                    <BordEndField />
                  </FieldsSplitColumns>
                </GroupContainer>
                <DistanceField />
                <FieldsSplitColumns>
                  <SelectRF
                    label={'Марка бортового камня'}
                    name={'bound_stone_mark_id'}
                    options={this.getBoundStoneMarkOptions()}
                    disabled={
                      !(editMode && isEditCurrentObjectIsExternalSystem)
                    }
                    required={isMandatory(
                      card.type_id,
                      'bound_stone_mark_id',
                      required,
                    )}
                  />
                  {materialInput}
                  <CheckboxRF
                    label={'Лотковая зона'}
                    name={'is_gutter_zone'}
                    disabled={
                      !(editMode && isEditCurrentObjectIsExternalSystem)
                    }
                  />
                  <CheckboxRF
                    label={'Прилегает к проезжей части'}
                    name={'near_roadway'}
                    disabled={
                      !(editMode && isEditCurrentObjectIsExternalSystem)
                    }
                  />
                </FieldsSplitColumns>
                <DescriptionField />
              </div>

              <div hidden={true} id={'docs'}>
                <OtherDocumentsRF />
              </div>
            </div>
            <div hidden={true} id="versions">
              <Versions
                card={card}
                disabled={createMode}
                key={`versions-${card.root_id}`}
              />
            </div>
          </div>
        </CardAbstract>
      </CardContainer>
    );
  }
}

const boardStoneDocumentsReducer = documentsReducer({
  oghGroupCode: 'yard_subobject',
});

/**
 * Функция mapStateToProps.
 *
 * @param {object} state - Стейт.
 * @param {object} props - Пропсы.
 * @returns {object}
 */
const mapStateToProps = (state, props) => {
  const parse = getParseAttribute(props);
  const defaultFieldValues = getDefaultFieldValues(state, props);

  const result = {
    createMode: isCreateMode(state, props),
    editMode: isEditMode(state, props),

    enableReinitialize: true,

    formValues: getFormValues(state),
    initialValues: {
      bord_begin: parse('bord_begin'),
      bord_end: parse('bord_end'),
      bound_stone_mark_id:
        defaultFieldValues.bound_stone_mark_id ||
        parse('bound_stone_mark_id.bound_stone_mark'),
      customer_id: parse('customer_id'),
      description: parse('description'),
      distance: parse('distance'),
      endDate: parseDate(props.card.end_date),
      flat_element_type_id: parse('flat_element_type_id.flat_element_type'),
      is_gutter_zone: parse('is_gutter_zone') || 0,
      material_id: getBoundStoneMaterial(state, props),
      near_roadway: parse('near_roadway'),
      odh_axis: parse('odh_axis'),
      odh_side_id: parse('odh_side_id.odh_side'),
      owner_id: parse('owner_id'),
      startDate: parseDate(props.card.start_date),
    },
    onSubmit: onSubmit.bind({ props }),
    required: isRequired(state, props),
    validate,
    viewing: [isEditMode(state, props), isCreateMode(state, props)].every(
      (item) => !item,
    ),
  };

  return boardStoneDocumentsReducer(result, state, props);
};

/**
 * Функция создания атрибутов.
 *
 * @param {object} formValues - Значения формы.
 * @returns {{file_list: *}}
 */
function createAttribute(formValues) {
  return {
    ...createDocumentsAttribute(formValues),
  };
}

const numericFields = [
  { name: 'bord_begin', type: 'decimal', zero: true },
  { name: 'bord_end', type: 'decimal', zero: true },
  { name: 'distance', positive: true, type: 'decimal', zero: false },
];

const dictFields = [
  'odh_side_id',
  'flat_element_type_id',
  'bound_stone_mark_id',
];

/**
 * Функция валидации.
 *
 * @param {object} values - Значения формы.
 * @returns {object}
 */
const validate = (values) => ({
  ...validateMaterial(values),
  ...validateNumeric(values, numericFields),
});

/**
 * Функция сабмита.
 *
 * @param {object} formValues - Значения формы.
 * @returns {void}
 */
function onSubmit(formValues) {
  const { card } = this.props;
  const values = { ...formValues };

  delete values.parent_name;

  validateRequiredFieldsAsync(values, mandatoryFields[card.type_id]);
  values.near_roadway = Boolean(values.near_roadway);

  const material = find(card.material, { name: values.material_id });
  const formattedValuesTmp = { ...values, material_id: material?.id };

  const formattedValues = {
    ...formattedValuesTmp,
    ...createAttribute(formattedValuesTmp),
  };

  submitCard(
    this.props,
    transformValues(formattedValues, {
      dictFields,
      numericFields,
    }),
  );
}

/**
 * Функция mapDispatchToProps.
 *
 * @param {Function} dispatch - Функция dispatch.
 * @returns {{changeFieldValue: mapDispatchToProps.changeFieldValue}}
 */
const mapDispatchToProps = (dispatch) => ({

  /**
   * Метод изменения поля.
   *
   * @param {string} field - Имя.
   * @param {*} value - Значение формы.
   */
  changeFieldValue: (field, value) => {
    dispatch(change('editorCard', field, value));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(reduxForm({ form: 'editorCard' })(BoardStoneCard));
