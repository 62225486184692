import { OZN, YARD } from 'app/constants/oghTypes';
import { createNumberedList } from 'app/utils/createNumberedList';

/**
 * Получение подсказки для поля __Общая уборочная площадь, кв.м__.
 *
 * @param {number} typeId - Тип ОГХ.
 * @returns {JSX.Element}
 */
export const getHintTotalCleanArea = (typeId) => {
  const squareChildren = [
    '"Плоскостные сооружения".',
    '"Дорожно-тропиночные сети"',
    '"Закрытые велопарковки"',
    '"Места сборов отходов"',
    '"Газоны"',
  ];

  switch (typeId) {
    case YARD: {
      squareChildren.push({
        children: ['Пешеходный мост', 'Пирс', 'Cухой фонтан'],
        item: '"Инженерные сооружения"',
      });
      break;
    }
    case OZN: {
      squareChildren.push({
        children: [
          'Бассейн',
          'Пешеходный мост',
          'Пирс (причал)',
          'Променад',
          'Сухой фонтан',
        ],
        item: '"Инженерные сооружения"',
      });
      break;
    }
    default: {
      break;
    }
  }

  return createNumberedList({
    header: 'Рассчитывается как сумма значений',
    list: [
      {
        children: squareChildren,
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"ИОКС типа Отмостка"'],
        item: '"Площадь, кв.м."',
      },
      {
        children: ['"Цветники"'],
        item: '"Общая площадь цветника, кв.м"',
      },
      {
        children: [
          'Элементы организации рельефа типа Лестницы',
          'Элементы благоустройства для маломобильных групп населения типа Пандус',
        ],
        item: 'Количество, кв.м.',
      },
    ],
  });
};
