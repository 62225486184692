import MapDiff from 'app/components/card/ogh/versions/CompareReport/MapDiff';
import React from 'react';

import { useGetDataMapDiff } from './useGetData.MapDiff';

 
/**
 * Обертка для получение данных таба.
 *
 * @param {object} props - Пропсы.
 * @param {object} props.etalon - Объект сравниваемый.
 * @param {object} props.copy - C чем сравниваем.
 * @returns {JSX.Element} - JSX.
 * @example <MapDiffWrapper etalon={etalon} copy={copy}/>
 */
export const MapDiffWrapper = ({ etalon, copy }) => {
  const { data } = useGetDataMapDiff(etalon, copy);
  return (
    <MapDiff
      className={'compareReport__diff'}
      copy={copy}
      etalon={etalon}
      geodiff={data}
    />
  );
};
