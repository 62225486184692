import { useGetDictionary } from 'app/api/hooks/autocomplete/useGetDictionary';
import { Select } from 'core/uiKit/inputs/selects/Select';
import React from 'react';
import { SelectPreparedProps } from 'types/inputs';

export const CONTAINER_TYPE_ID_NAME = 'container_type_id';

interface Attribute {
  code?: string;
  name?: string;
  migr_code?: string[];
  parent_ogh_object_type_list?: number[];
  has_separate_garbage_collection?: boolean;
}

export interface IContainerTypeData {
  id: number;
  attribute?: Attribute;
}

export interface IContainerTypeIdProps extends SelectPreparedProps {
  filter?: (data: IContainerTypeData[]) => IContainerTypeData[];
}

/**
 * ## Тип МСО.
 *
 * `type = Select`
 * `name = container_type_id`
 * `dict = containerType`.
 *
 * @param {IContainerTypeIdProps} props - Пропсы.
 * @returns JSX.
 */
export const ContainerTypeId = (props: IContainerTypeIdProps) => {
  const dict = 'containerType';

  const { data } = useGetDictionary<IContainerTypeData[]>(dict);

  const options = data?.map((option) => ({
    id: option.id,
    name: option?.attribute?.name,
  }));

  return (
    <Select
      {...props}
      label={'Тип МНО'}
      name={CONTAINER_TYPE_ID_NAME}
      options={options}
    />
  );
};
