/* eslint-disable */
import React from 'react';
import { useGetCurrentCard } from 'app/api/hooks/useGetCurrentCard';
import { cards } from 'app/pages/cardsOgh/cards';
import { Layout } from 'app/pages/cardsOgh/components/Layout/Layout';

const CreateCardOgh = (props) => {
  const { typeId } = useGetCurrentCard();
  const Component = cards[typeId]?.component;

  return Component ? (
    <Layout map={cards[typeId].map}>
      <Component {...props} />
    </Layout>
  ) : null;
};

 
export default CreateCardOgh;
