/* eslint-disable  */
/**
 *
 * @param root0
 * @param root0.username
 * @param root0.password
 */
export const validateForm = ({ username, password }) => {
  let errors = {};
  if (!username) {
    errors.username = 'Необходимо ввести имя пользователя';
  }
  if (!password) {
    errors.password = 'Необходимо ввести пароль';
  }
  return Object.keys(errors).length ? errors : null;
};
